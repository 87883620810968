import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Logo from "../components/Logo"
import {
  navy,
  neutral,
  yellow,
  typeScale,
  transition,
  width,
  perfectFourth,
} from "../utils/"

const Footer = () => {
  return (
    <PageFooter>
      <InnerFooter>
        <SVGWrapper>
          <Link to="/">
            <Logo />
          </Link>
        </SVGWrapper>
        <FooterText>
          Designed and created by{" "}
          <a href="https://www.lauraleeflores.com">Lauralee Flores</a>.
          <br /> Built on GatsbyJS. Hosted on Netlify. ©{" "}
          {new Date().getFullYear()}
        </FooterText>
      </InnerFooter>
    </PageFooter>
  )
}

const PageFooter = styled.footer`
  background: ${navy[500]};
  margin-block-start: ${perfectFourth[600]};
`

const InnerFooter = styled.div`
  max-inline-size: ${width.reg};
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding-block-start: ${perfectFourth[400]};
  padding-block-end: ${perfectFourth[400]};
  padding-inline-start: ${perfectFourth[200]};
  padding-inline-end: ${perfectFourth[200]};
  display: flex;
`
const SVGWrapper = styled.div`
  inline-size: 15%;
  display: flex;
  align-items: center;
`

const FooterText = styled.p`
  color: ${neutral[100]};
  text-align: end;
  float: end
  ;
  inline-size: 85%;
  font-size: ${typeScale.smallText};
  a {
    text-decoration-color: ${yellow[500]};
    transition: ${transition.reg};
    color: ${neutral[100]};
    &:hover {
      color: ${yellow[500]};
    }
  }
`

export default Footer
