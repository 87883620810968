import React from "react"
import styled from "styled-components"
import { borderRadius, blue } from "../../utils"
import {
  FiHome,
  FiCoffee,
  FiBriefcase,
  FiMail,
  FiMenu,
  FiX,
} from "react-icons/fi"

const PureCSS = () => {
  return (
    <PureCSSWrapper>
      <Check type="checkbox" id="check" />
      <NavButtons htmlFor="check">
        <FiMenu className="menu" />
        <FiX className="close" />
      </NavButtons>
      <Sidebar>
        <SidebarHeader>Comp-X</SidebarHeader>
        <ul>
          <SidebarItem>
            <SidebarLink href="#">
              <FiHome />
              Home
            </SidebarLink>
          </SidebarItem>
          <SidebarItem>
            <SidebarLink href="#">
              <FiCoffee />
              About
            </SidebarLink>
          </SidebarItem>
          <SidebarItem>
            <SidebarLink href="#">
              <FiBriefcase />
              Work
            </SidebarLink>
          </SidebarItem>
          <SidebarItem>
            <SidebarLink href="#">
              <FiMail />
              Contact
            </SidebarLink>
          </SidebarItem>
        </ul>
      </Sidebar>
      <Content>
        <div>
          <h2>Click the menu in the top left corner</h2>
          <p>You'll see the off-canvas menu slide onto the page.</p>
        </div>
      </Content>
    </PureCSSWrapper>
  )
}
const Content = styled.section`
  background: ${blue[100]};
  width: 100%;
  height: 100%;
  transition: 0.5s;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${blue[800]};
`
const PureCSSWrapper = styled.div`
  height: 22rem;
  max-height: 22rem;
  width: 100%;
  border-radius: ${borderRadius.reg};
  border: 1px solid ${blue[500]};
  overflow: hidden;
  position: relative;
`
const Sidebar = styled.nav`
  position: absolute;
  left: -225px;
  inset-inline-start: -225px;
  width: 225px;
  height: 100%;
  background: ${blue[700]};
  transition: 0.5s;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
`
const NavButtons = styled.label`
  svg {
    position: absolute;
    top: 5px;
    inset-block-start: 0;
    left: 5px;
    inset-inline-start: 0;
    cursor: pointer;
    background: ${blue[900]};
    color: white;
    width: 3.5rem;
    height: 3.5rem;
    padding: 1rem;
    border-radius: ${borderRadius.reg};
    &.close {
      left: 0;
      top: 0;
      border-radius: 0;
      z-index: 1;
      transition: 0.5s;
    }
  }
`
const SidebarHeader = styled.header`
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 6px;
  color: white;
  text-align: left;
  text-align: start;
  height: 3.5rem;
  display: flex;
  align-items: center;
  padding-inline-start: 4.9rem;
  background: ${blue[800]};
`
const SidebarItem = styled.li`
  padding: 0.25rem 0;
`
const SidebarLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  color: white;
  padding: 1rem 0 1rem 60px;
  padding-inline: 0 60px;
  padding-block-start: 1rem;
  padding-block-end: 1rem;
  transition: 0.4s;
  text-decoration: none;
  &:hover {
    padding-inline-start: 70px;
    background: ${blue[800]};
  }
  svg {
    margin-inline-end: 1rem;
    margin-inline-end: 1rem;
  }
`

const Check = styled.input`
  display: none;
  &:checked ~ nav {
    left: 0;
  }
  &:checked ~ ${NavButtons} svg.menu {
    opacity: 0;
    pointer-events: none;
  }
  &:not(:checked) ~ ${NavButtons} svg.close {
    left: -250px;
    inset-inline-start: -250px;
    pointer-events: none;
  }
  &:checked ~ ${Content} {
    margin-inline-start: 250px;
  }
`

export default PureCSS
