import React, { useRef, useState, useEffect } from "react";
import styled, { css } from "styled-components"
import { neutral, borderRadius, perfectFourth } from "../../utils"
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/xq-light.css';


let CodeMirror = null;
if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
  CodeMirror = require('react-codemirror2').Controlled;
  
  require('codemirror/mode/xml/xml');
  require('codemirror/mode/css/css');
}

const CSSEditor = ({ initialHtml, initialCss, defaultCss = '' }) => {
  const iframeRef = useRef();
  const [html, setHtml] = useState(initialHtml);
  const [css, setCss] = useState(initialCss);

  const updatePreview = () => {
    const frame = iframeRef.current.contentWindow.document;
    frame.open();
    frame.write(html + `<style>${defaultCss}${css}</style>`);
    frame.close();
  }

  useEffect(updatePreview, []);

  if(!CodeMirror) {
    return null;
  }


  return (
    <EditorWrapper>
      <Html>
        <CodeMirror
          value={html}
          options={{
            mode: 'xml',
            theme: 'xq-light',
            lineNumbers: false
          }}
          onBeforeChange={(editor, data, value) => {
            setHtml(value);
          }}
          onChange={(editor, data, value) => {
            updatePreview();
          }}
        />
      </Html>
      <Css>
        <CodeMirror
          value={css}
          options={{
            mode: 'css',
            theme: 'xq-light',
            lineNumbers: false
          }}
          onBeforeChange={(editor, data, value) => {
            setCss(value);
          }}
          onChange={(editor, data, value) => {
            updatePreview();
          }}
        />
      </Css>
      <Showcase ref={iframeRef}></Showcase>
    </EditorWrapper>
  );
};

const DefaultStyling = css`
  height: 100%;
  width: 100%;
  border: 2px solid ${neutral[200]};
  border-radius: ${borderRadius.reg};
  color: ${neutral[700]};
  box-sizing: border-box;
  resize: none;
`

const CodeBoxName = css`
  font-size: 5rem;
`

const EditorWrapper = styled.div`
  background: ${neutral[200]};
  border-radius: ${borderRadius.reg};
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-gap: ${perfectFourth[200]};
  justify-content: center;
  align-items: center;
  padding: ${perfectFourth[200]};
  height: 17rem;
  .CodeMirror {
    height: 100%;
    border-radius: ${borderRadius.reg};
  }
  .react-codemirror2 {
    height: 100%;
  }
  .cm-s-xq-light span.cm-tag {
    color: hsl(175, 90%, 34%);
  } 
  .cm-s-xq-light span.cm-keyword {
    color: hsl(205, 100%, 34%);
  }
  .cm-s-xq-light span.cm-number {
    color: hsl(257, 80%, 70%);
  }
`

const Html = styled.div`
  display: none;
`

const Css = styled.div`
  ${DefaultStyling};
  grid-row: 1 / -1;
  &:after {
    content: "CSS"
    ${CodeBoxName};
  }
`

const Showcase = styled.iframe`
  ${DefaultStyling};
  grid-column-start: 2;
  grid-row: 1 / -1;
  border: 2px solid ${neutral[200]};
  background: ${neutral.white};
`

export default CSSEditor;
