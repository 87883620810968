import React from "react"
import Helmet from "react-helmet"
import useSiteMetadata from "../hooks/useSiteMetadata"
import useFavicon from "../hooks/useFavicon"
import { useLocation } from "@reach/router"

const SEO = ({
  title,
  description,
  image,
  author,
  SVGFavicon,
  IcoFavicon,
  SafariFavicon,
}) => {
  const { pathname } = useLocation()

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    defaultAuthor,
    siteUrl,
    defaultImage,
  } = useSiteMetadata()

  const { svg, ico, safari } = useFavicon()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    author: author || defaultAuthor,
    SVGFavicon: `${siteUrl}${svg.nodes[0].favicon}`,
    IcoFavicon: `${siteUrl}${ico.nodes[0].favicon}`,
    SafariFavicon: `${siteUrl}${safari.nodes[0].favicon}`,
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <html lang="en-us" dir="ltr" />
      <link rel="canonical" href={seo.url} />
      <meta name="description" content={seo.description} />
      {image && <meta name="image" content={seo.image} />}
      <link rel="icon" href={seo.SVGFavicon} type="image/svg+xml" sizes="any" />
      <link rel="alternate icon" href={IcoFavicon}></link>
      <link rel="mask-icon" href={SafariFavicon} color="#21DCBE"></link>

      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={seo.author} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <link
        href="https://fonts.googleapis.com/css2?family=Livvic:ital,wght@0,500;0,900;1,500&display=swap"
        rel="stylesheet"
      ></link>
    </Helmet>
  )
}

export default SEO
