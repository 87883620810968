import React from "react"
import styled from "styled-components"
import {
  borderRadius,
  neutral,
  blue,
  yellow,
  red,
  teal,
  purple,
  navy,
  typeScale,
} from "../../utils"

const HeadlineSmooth = () => {
  return (
    <AnimationWrapper>
      <Headline>Learn and Master CSS</Headline>
    </AnimationWrapper>
  )
}

const AnimationWrapper = styled.div`
  background: ${neutral[200]};
  height: 10rem;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: ${borderRadius.reg};
`

const Headline = styled.h1`
  font-family: "Livvic", sans-serif;
  font-weight: 900;
  text-align: center;
  font-size: ${typeScale.header2};
  background-color: ${navy[400]};
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    60deg,
    ${navy[400]},
    ${purple[400]},
    ${red[400]},
    ${teal[400]},
    ${blue[400]},
    ${yellow[400]}
  );
  animation: text-animation-rev 1.5s ease forwards;
  &:hover {
    animation: text-animation-hover 0.5s ease-in forwards;
  }
  @keyframes text-animation-rev {
    0% {
      background-size: 700%;
    }
    20% {
      background-size: 700%;
    }
    40% {
      background-size: 50%;
    }
    100% {
      background-size: 100%;
    }
  }
  @keyframes text-animation-hover {
    0% {
      background-size: 100%;
    }
    80% {
      background-size: 700%;
    }
    100% {
      background-size: 700%;
    }
  }
`

export default HeadlineSmooth
