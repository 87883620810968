export const base = {
  size: "1rem",
}
export const perfectFourth = {
  100: "clamp(.1rem, .2rem + .3vw, .3rem)",
  200: "clamp(.5rem, .5rem + .5vw, .75rem)",
  300: "clamp(.75rem, .5rem + .9vw, 1rem)",
  400: "clamp(1rem, 1rem + .6vw, 1.33rem)",
  500: "clamp(1.33rem, 1rem + 1.3vw, 1.77rem)",
  600: "clamp(1.77rem, 1rem + 2.3vw, 2.36rem)",
  700: "clamp(2.36rem, 1rem + 3.5vw, 3.15rem)",
  800: "clamp(3.15rem, 1rem + 5.4vw, 4.2rem)",
}

export const typeScale = {
  header1: "clamp(3.15rem, 1rem + 5.4vw, 4.2rem)", // 67.344px or 4.2rem
  header2: "clamp(2.36rem, 1rem + 3.5vw, 3.15rem)", // 50.512px or 3.157rem
  header3: "clamp(1.77rem, 1rem + 2.3vw, 2.36rem)", // 37.904px or 2.369rem
  header4: "clamp(1.33rem, 1rem + 1.3vw, 1.77rem)", // 28.432px or 1.777rem
  header5: "clamp(1rem, 1rem + .6vw, 1.33rem)", // 21.328px or 1.333rem
  subtitle: "clamp(.9rem, .8rem + .6vw, 1.2rem)",
  paragraph: "clamp(.75rem, .5rem + .9vw, 1rem)", // 16px or 1rem
  smallText: "clamp(.5rem, .5rem + .5vw, .75rem)", // 12px or 0.75rem
}

export const width = {
  narrow: "500px",
  blogContent: "700px",
  blogWide: "870px",
  reg: "950px",
}
