import React from "react"
import { graphql } from "gatsby"
import { BlogTitleHeader } from "../components/Headers"
import Layout from "../components/Layout"
import { BlogTitleWrapper, Dots } from "../components/Layout"
import styled, { css } from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import {
  typeScale,
  yellow,
  width,
  borderRadius,
  perfectFourth,
  transition,
  neutral,
  primaryFont,
  breakpoint,
  boxShadow,
} from "../utils"
import Image from "gatsby-image"
import SEO from "../components/seo"
// import BlogCallToAction from "../components/Blog/BlogCallToAction";

const postTemplate = ({ data }) => {
  const {
    mdx: {
      frontmatter: { title, author, summary, image },
      body,
    },
  } = data
  return (
    <>
      <SEO
        title={title}
        author={author}
        description={summary}
        image={image.childImageSharp.fluid.src}
      />
      <Layout>
        <BlogTitleWrapper>
          <div className="flow">
            <BlogTitleHeader>{title}</BlogTitleHeader>
            <Author>By {author}</Author>
            <PostTitleHR />
            <PostSummary>{summary}</PostSummary>
          </div>
        </BlogTitleWrapper>
        <ImageWrapper>
          <Dots>
            <Image fluid={image.childImageSharp.fluid} alt={summary} />
          </Dots>
        </ImageWrapper>
        <PostBody>
          <MDXRenderer>{body}</MDXRenderer>
        </PostBody>
        {/* <BlogCallToAction title="testing" button="button" /> */}
      </Layout>
    </>
  )
}

export const query = graphql`
  query GetSinglePost($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        summary
        category
        author
        slug
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
  }
`

export const Code = css`
  font-family: ${primaryFont};
  padding-block-start: 0px;
  padding-block-end: 0px;
  padding-inline-start: 0.25rem;
  padding-inline-end: 0.25rem;

  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0.05rem;
  margin-inline-end: 0.05rem;
  border-radius: 0.2rem;
  white-space: normal;
  font-size: ${typeScale.paragraph};
  letter-spacing: 0.5px;
  vertical-align: text-bottom;
`

export const Author = styled.p`
  @media ${breakpoint.md} {
    text-align: center;
  }
`

export const PostBody = styled.article`
  h2,
  h3,
  h4,
  h5 {
    line-height: 1.1;
  }
  h2 {
    font-size: ${typeScale.header2};
  }
  h3 {
    font-size: ${typeScale.header3};
  }
  h4 {
    font-size: ${typeScale.header4};
  }
  h5 {
    font-size: ${typeScale.header5};
    font-weight: 900;
  }
  p,
  li {
    font-size: ${typeScale.header5};
    font-weight: 500;
  }
  .content-wrapper {
    max-inline-size: ${width.blogContent};
    margin-inline-start: auto;
    margin-inline-end: auto;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    a {
      text-decoration-color: ${yellow[500]};
      transition: ${transition.reg};
      background: transparent;
      color: ${neutral[700]};
      background: linear-gradient(
        to top,
        ${yellow[400]} 50%,
        ${neutral[100]} 50%
      );
      background-size: 100% 200%;
      background-position: right top;
      &:hover {
        background-position: right bottom;
      }
    }
    code {
      background: ${neutral[300]};
      ${Code}
    }
    img,
    p img {
      border-radius: ${borderRadius.reg};
    }
  }
  .content-upgrade {
    max-inline-size: ${width.blogContent};
    margin-inline-start: auto;
    margin-inline-end: auto;
    margin-block-end: 3rem;
  }
  .flow > * + * {
    margin-block-start: ${perfectFourth[500]};
  }
  .project-wrapper,
  .image-wrapper {
    max-inline-size: ${width.blogWide};
    margin-block-start: 2rem;
    margin-block-end: 2rem;
    margin-inline-start: auto;
    margin-inline-end: auto;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
  }
  .image-wrapper {
    .gatsby-resp-image-wrapper {
      box-shadow: ${boxShadow.reg};
    }
  }
  .call-out {
    border: 2px dashed ${neutral[300]};
    border-radius: ${borderRadius.reg};
    padding: ${perfectFourth[200]};
    p {
      font-size: ${typeScale.paragraph};
    }
  }
`

export const PostTitleHR = styled.div`
  inline-size: 5rem;
  block-size: 4px;
  background: ${yellow[500]};
  border-radius: ${borderRadius.reg};
  @media ${breakpoint.md} {
    margin-inline-start: auto;
    margin-inline-end: auto;
  }
`

export const PostSummary = styled.p`
  font-size: ${typeScale.header5};
  @media ${breakpoint.md} {
    text-align: center;
  }
`

export const ImageWrapper = styled.div`
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-inline-size: ${width.blogWide};
  overflow: hidden;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
`

export default postTemplate
