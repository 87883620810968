export const neutral = {
  white: "hsl(255, 100%, 100%)",
  100: "hsl(210, 17%, 99%)",
  200: "hsl(210, 25%, 95%)",
  300: "hsl(208, 25%, 86%)",
  400: "hsl(208, 24%, 80%)",
  500: "hsl(208, 24%, 74%)",
  600: "hsl(207, 19%, 61%)",
  700: "hsl(206, 14%, 41%)",
  800: "hsl(202, 11%, 29%)",
  900: "hsl(203, 7%, 20%)",
}

export const navy = {
  100: "hsl(229, 50%, 98%)",
  200: "hsl(229, 50%, 93%)",
  300: "hsl(229, 18%, 68%)",
  400: "hsl(229, 18%, 61%)",
  500: "hsl(229, 18%, 45%)",
  600: "hsl(229, 24%, 35%)",
  700: "hsl(229, 28%, 25%)",
  800: "hsl(229, 30%, 22%)",
}

export const blue = {
  100: "hsl(183, 100%, 97%)",
  200: "hsl(182, 100%, 91%)",
  300: "hsl(186, 100%, 86%)",
  400: "hsl(191, 100%, 76%)",
  500: "hsl(192, 100%, 51%)",
  600: "hsl(196, 100%, 40%)",
  700: "hsl(205, 100%, 34%)",
  800: "hsl(209, 100%, 24%)",
  900: "hsl(210, 100%, 14%)",
}

export const yellow = {
  100: "hsl(40, 100%, 98%)",
  200: "hsl(40, 100%, 96%)",
  300: "hsl(42, 100%, 91%)",
  400: "hsl(46, 100%, 82%)",
  500: "hsl(46, 100%, 68%)",
  600: "hsl(40, 100%, 63%)",
  700: "hsl(36, 100%, 59%)",
  800: "hsl(30, 100%, 58%)",
}

export const teal = {
  100: "hsl(177, 100%, 98%)",
  200: "hsl(176, 100%, 95%)",
  300: "hsl(173, 90%, 88%)",
  400: "hsl(171, 79%, 80%)",
  500: "hsl(170, 74%, 50%)",
  600: "hsl(173, 79%, 42%)",
  700: "hsl(175, 90%, 34%)",
  800: "hsl(177, 100%, 30%)",
}

export const purple = {
  100: "hsl(257, 50%, 98%)",
  200: "hsl(257, 60%, 97%)",
  300: "hsl(257, 70%, 93%)",
  400: "hsl(257, 90%, 87%)",
  500: "hsl(257, 100%, 77%)",
  600: "hsl(257, 80%, 70%)",
  700: "hsl(257, 60%, 59%)",
  800: "hsl(257, 50%, 46%)",
  900: "hsl(257, 40%, 20%)",
}

export const red = {
  100: "hsl(333, 100%, 98%)",
  200: "hsl(342, 100%, 95%)",
  300: "hsl(347, 100%, 92%)",
  400: "hsl(354, 100%, 81%)",
  500: "hsl(357, 100%, 72%)",
  600: "hsl(353, 100%, 65%)",
  700: "hsl(345, 100%, 59%)",
  800: "hsl(337, 100%, 50%)",
  900: "hsl(332, 100%, 14%)",
}
