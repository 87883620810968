import React from "react"
import { Link } from "gatsby"
import Logo from "../components/Logo"
import styled from "styled-components"
import {
  teal,
  navy,
  transition,
  breakpoint,
  perfectFourth,
  width,
} from "../utils"

const Topnav = () => {
  return (
    <Navbar>
      <LogoWrapper>
        <Link to="/">
          <Logo />
        </Link>
      </LogoWrapper>

      <NavLinks>
        <Link to="/" activeClassName="active">
          Home
        </Link>
        <Link to="/academy/" activeClassName="active" partiallyActive={true}>
          Academy
        </Link>
        <Link to="/blog/" activeClassName="active" partiallyActive={true}>
          Blog
        </Link>
      </NavLinks>
    </Navbar>
  )
}

const Navbar = styled.nav`
  max-inline-size: ${width.reg};
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding-block-start: ${perfectFourth[400]};
  padding-block-end: ${perfectFourth[400]};
  padding-inline-start: ${perfectFourth[200]};
  padding-inline-end: ${perfectFourth[200]};
  display: flex;
  @media ${breakpoint.md} {
    flex-direction: column;
  }
`

const LogoWrapper = styled.div`
  inline-size: 65%;
  padding-inline-start: ${perfectFourth[200]};
  padding-inline-end: ${perfectFourth[200]};
  @media ${breakpoint.md} {
    width: 100%;
    text-align: center;
    margin-block-end: 1rem;
  }
`

const NavLinks = styled.div`
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  inline-size: 35%;
  display: flex;
  justify-content: space-around;
  @media ${breakpoint.md} {
    inline-size: 100%;
  }
  a {
    color: ${navy[600]};
    display: inline-block;
    margin-inline-start: 0.5rem;
    margin-inline-end: 0.5rem;
    padding-inline-start: 2px;
    padding-inline-end: 2px;
    text-decoration: none;
    line-height: 1.9;
    border-block-end: 3px solid transparent;
    transition: ${transition.reg};
    &.active {
      border-block-end: 3px solid ${teal[500]};
      font-weight: 900;
      ~ a {
        color: ${navy[400]};
      }
    }
    &:active,
    &:hover,
    &:focus {
      border-block-end: 3px solid ${teal[500]};
      color: ${navy[600]};
    }
  }
`

export default Topnav
