import React from "react"
import styled from "styled-components"
import {
  borderRadius,
  neutral,
  blue,
  yellow,
  typeScale,
  perfectFourth,
} from "../../utils"

const AnimationExample = () => {
  return (
    <AnimationWrapper>
      <Button>
        <Text>Hover Over Me</Text>
      </Button>
    </AnimationWrapper>
  )
}

const AnimationWrapper = styled.div`
  background: ${neutral[300]};
  height: 10rem;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: ${borderRadius.reg};
`

const Text = styled.span`
  background-image: linear-gradient(
    90deg,
    ${yellow[400]} 50%,
    ${blue[600]} 50%
  );
  background-size: 240%;
  background-position: top left;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: button-animation-ltr 0.65s 0.15s ease-out forwards;
  @keyframes button-animation-ltr {
    0% {
      background-position: top left;
    }
    100% {
      background-position: top right;
    }
  }
  @keyframes button-animation-ltr-rev {
    0% {
      background-position: top right;
    }
    100% {
      background-position: top left;
    }
  }
`

const Button = styled.a`
  border-radius: ${borderRadius.pill};
  padding: ${perfectFourth[200]} ${perfectFourth[600]};
  font-size: ${typeScale.header5};
  font-weight: bold;
  letter-spacing: 1px;

  background-image: linear-gradient(
    90deg,
    ${blue[600]} 50%,
    ${yellow[400]} 50%
  );
  background-size: 240%;
  background-position: top left;
  animation: button-animation-ltr 0.65s 0.15s ease-out forwards;
  &:hover,
  &:hover ${Text} {
    cursor: pointer;
    animation: button-animation-ltr-rev 0.65s ease-out forwards;
  }
`

export default AnimationExample
