import React, { useState } from "react"
import styled from "styled-components"
import { borderRadius } from "../../utils"

const ZellProjectBefore = () => {
  const [open, setOpen] = useState(false)
  return (
    <ZellWrapper>
      <div className={open ? "offsite-is-open" : "offsite-is-closed"}>
        <OffsiteContainer>
          <Navigation>
            <ul>
              <li>
                <button>Home</button>
              </li>
              <li>
                <button>Work</button>
              </li>
              <li>
                <button>About</button>
              </li>
              <li>
                <button>Contact</button>
              </li>
            </ul>
          </Navigation>
        </OffsiteContainer>

        <SiteContainer>
          <Header>Click the button to open the sidebar!</Header>
          <ZellButton onClick={() => setOpen(!open)}>Menu</ZellButton>
        </SiteContainer>
      </div>
    </ZellWrapper>
  )
}

const OffsiteContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -14rem;
  width: 14rem;
  background-color: var(--menuBackgroundColor);
  transition: transform 0.3s ease-out;
`

const SiteContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 4rem;
  padding-inline-start: 2.5rem;
  max-inline-size: 33rem;
  transition: transform 0.3s ease-out;
`

const ZellWrapper = styled.div`
  --textColor: #031129;
  --backgroundColor: #41b89a;
  --menuTextColor: #9ebed0;
  --menuTextHoverColor: #eaf0f3;
  --menuBackgroundColor: #031129;
  border-radius: ${borderRadius.reg};
  font-size: 125%;
  line-height: 1.4;
  background-color: var(--backgroundColor);
  color: var(--textColor);
  position: relative;
  overflow: hidden;
  .offsite-is-open,
  .offsite-is-closed {
    transition: 0.4s;
  }
  .offsite-is-open ${SiteContainer}, .offsite-is-open ${OffsiteContainer} {
    transform: translateX(14rem);
  }
  .offsite-is-open button {
    background-color: var(--textColor);
    color: var(--backgroundColor);
    border-color: var(--textColor);
  }
  .offsite-is-open nav button {
    color: var(--menuTextColor);
    &:hover {
      color: var(--menuTextHoverColor);
    }
  }
`

const Header = styled.h2`
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: 1.25;
`

const ZellButton = styled.button`
  border: 2px solid var(--textColor);
  background: transparent;
  transition: background-color 0.3s ease-out;
  padding: 0.5rem 1rem;
  &:hover,
  &:focus {
    cursor: pointer;
    background-color: var(--textColor);
    color: var(--backgroundColor);
    border-color: var(--textColor);
  }
`

const Navigation = styled.nav`
  margin-top: 2rem;
  color: var(--menuTextColor);
  ul {
    list-style-type: none;
  }
  button {
    display: block;
    margin: 0;
    padding: 0.5rem 1rem;
    color: var(--menuTextColor);
    text-decoration: none;
    background: var(--menuBackgroundColor);
    border: none;
    &:hover {
      cursor: pointer;
      color: var(--menuTextHoverColor);
    }
  }
`

export default ZellProjectBefore
