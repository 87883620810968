import { graphql, useStaticQuery } from "gatsby"

const useFavicon = () => {
  const icon = useStaticQuery(graphql`
    query {
      svg: allFile(filter: { name: { eq: "favicon" }, ext: { eq: ".svg" } }) {
        nodes {
          favicon: publicURL
        }
      }
      ico: allFile(filter: { name: { eq: "favicon" }, ext: { eq: ".ico" } }) {
        nodes {
          favicon: publicURL
        }
      }
      safari: allFile(
        filter: { name: { eq: "safari-pinned-tab" }, ext: { eq: ".svg" } }
      ) {
        nodes {
          favicon: publicURL
        }
      }
    }
  `)

  return icon
}

export default useFavicon
