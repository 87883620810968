import { createGlobalStyle } from "styled-components"
import { primaryFont } from "./typography"
import { perfectFourth, typeScale } from "./size"
import { neutral, navy, yellow } from "./colors"

export const GlobalStyle = createGlobalStyle`
/* ################## */
/* START Reset Styles */
/* ################## */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-inline-size: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01s !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01s !important;
    scroll-behavior: auto !important;
  }
}
/* ################## */
/* END Reset Styles */
/* ################## */

/* ################## */
/* START Global Styles */
/* ################## */
:root {
  --flow-space: ${perfectFourth[600]};
}

body {
  background: ${neutral[100]};
  color: ${neutral[700]};
  line-height: 1.6;
  font-size: ${typeScale.paragraph};
  font-family: ${primaryFont}
}


:focus {
  outline: none;
}

h1,
h2,
h3,
h4 {
  font-weight: 900;
}

h5, 
p {
  font-weight: 500;
}

::selection {
  color: ${navy[600]};
  background: ${yellow[500]};
}

/* Scrollbar styles */
::-webkit-scrollbar {
  height: ${perfectFourth[300]};
}
::-webkit-scrollbar-track {
  background-color: ${navy[100]};
  border: 1px solid ${navy[200]};
  border-radius: 0px;
}
::-webkit-scrollbar-thumb {
  background-color: ${navy[700]};
  border-radius: 50px;
}

.flow > * + * {
  margin-top: var(--flow-space, ${perfectFourth[500]});
}

/* ################## */
/* END Global Styles */
/* ################## */

`
