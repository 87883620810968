import PureCSS from "../../../../src/posts/08-2020-off-canvas-menu-zell/PureCSS";
import ZellProject from "../../../../src/posts/08-2020-off-canvas-menu-zell/ZellProject";
import ZellProjectBefore from "../../../../src/posts/08-2020-off-canvas-menu-zell/ZellProjectBefore";
import Button from "../../../../src/components/Buttons";
import * as React from 'react';
export default {
  PureCSS,
  ZellProject,
  ZellProjectBefore,
  Button,
  React
};