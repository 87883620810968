import React, { useState } from "react";
import styled from "styled-components";
import { purple, borderRadius, transition } from "../../utils";

const WaveHiComponent = () => {
  const [waves, setWaves] = useState(0);
  const label = `👋 ${waves} ${waves === 1 ? "wave" : "waves"}`;
  return <Wave onClick={() => setWaves(waves + 1)}>{label}</Wave>;
};

const Wave = styled.button`
  background: ${purple[700]};
  border-radius: ${borderRadius.reg};
  color: white;
  font-size: 1.25rem;
  padding: 0.25rem 1rem;
  border: none;
  transition: ${transition.reg};
  &:hover {
    background: ${purple[800]};
    cursor: pointer;
  }
`;

export default WaveHiComponent;
