import React from "react"
import styled from "styled-components"
import {
  borderRadius,
  neutral,
  teal,
  purple,
  typeScale,
  perfectFourth,
} from "../../utils"

const ButtonSlideUp = () => {
  return (
    <ButtonWrapper>
      <Button>
        <Text>Hover Over Me</Text>
      </Button>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.div`
  background: ${neutral[300]};
  height: 10rem;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: ${borderRadius.reg};
  padding: ${perfectFourth[500]} ${perfectFourth[600]};
`

const Text = styled.span`
  background-image: linear-gradient(
    180deg,
    ${purple[700]} 50%,
    ${teal[500]} 50%
  );
  background-size: 200% 200%;
  background-position: bottom center;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: button-animation-bottom-to-top 0.5s 0.15s ease-out forwards;
  @keyframes button-animation-bottom-to-top {
    0% {
      background-position: bottom center;
    }
    100% {
      background-position: top center;
    }
  }
  @keyframes button-animation-bottom-to-top-rev {
    0% {
      background-position: top center;
    }
    100% {
      background-position: bottom center;
    }
  }
`

const Button = styled.a`
  border-radius: ${borderRadius.pill};
  padding: ${perfectFourth[200]} ${perfectFourth[600]};
  font-size: ${typeScale.header5};
  font-weight: bold;
  letter-spacing: 1px;

  background-image: linear-gradient(
    180deg,
    ${teal[500]} 50%,
    ${purple[700]} 50%
  );
  background-size: 200% 200%;
  background-position: bottom center;
  animation: button-animation-bottom-to-top 0.5s 0.15s ease-out forwards;
  &:hover,
  &:hover ${Text} {
    cursor: pointer;
    animation: button-animation-bottom-to-top-rev 0.5s ease-out forwards;
  }
`

export default ButtonSlideUp
