import React from "react"
import Topnav from "./Topnav"
import Footer from "./Footer"
import {
  GlobalStyle,
  width,
  perfectFourth,
  neutral,
  breakpoint,
  borderRadius,
} from "../utils"
import styled, { css } from "styled-components"

const Layout = ({ children }) => {
  return (
    <>
      <Topnav />
      <main>{children}</main>
      <Footer />
      <GlobalStyle />
    </>
  )
}

export const DefaultSpacing = css`
  margin-block-start: ${perfectFourth[400]};
  margin-inline-end: auto;
  margin-block-end: ${perfectFourth[800]};
  margin-inline-start: auto;
  padding: ${perfectFourth[200]};
`
export const NarrowWrapper = styled.section`
  ${DefaultSpacing}
  max-inline-size: ${width.narrow};
`
export const BlogTitleWrapper = styled.section`
  ${DefaultSpacing}
  max-inline-size: ${width.blogContent};
`
export const ContentWrapper = styled.section`
  position: relative;
  max-inline-size: ${width.reg};
  ${DefaultSpacing}
  @media ${breakpoint.md} {
    text-align: center;
  }
`

export const InsideWrapper = styled.section`
  max-inline-size: ${width.reg};
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding: ${perfectFourth[200]};
`

export const DefaultDots = css`
  --dot-shadow-size: 0.75rem;
  position: relative;
  background: ${neutral[200]};
  ${DefaultSpacing}

  &::before {
    content: "";
    display: block;
    inline-size: calc(100% + calc(var(--dot-shadow-size) * 2));
    block-size: calc(100% + calc(var(--dot-shadow-size) * 2));
    background-image: url("data:image/svg+xml,%3Csvg width='6px' height='6px' viewBox='0 0 6 6' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='%23CAC6B8' cx='1' cy='5' r='1'%3E%3C/circle%3E%3Ccircle fill='%23CAC6B8' cx='4' cy='2' r='1'%3E%3C/circle%3E%3C/svg%3E%0A");
    position: absolute;
    top: calc(var(--dot-shadow-size) * -1);
    left: calc(var(--dot-shadow-size) * -1);
    z-index: -1;
    opacity: 0.5;
    border-radius: ${borderRadius.reg};
  }
`
export const DotsFullWidth = styled.section`
  ${DefaultDots}
  &::before {
    inline-size: 100%;
    left: 0;
  }
`
export const Dots = styled.section`
  ${DefaultDots}
`

export default Layout
