import React from "react"
import styled, { css } from "styled-components"
import {
  neutral,
  red,
  borderRadius,
  boxShadow,
  perfectFourth,
  typeScale,
} from "../../utils"

const CalcMinimalDark = () => {
  return (
    <CalculatorWrapper>
      <Calculator>
        <Output>0</Output>
        <Keys>
          <Operator>+</Operator>
          <Operator>-</Operator>
          <Operator>&times;</Operator>
          <Operator>÷</Operator>
          <Key>7</Key>
          <Key>8</Key>
          <Key>9</Key>
          <Key>4</Key>
          <Key>5</Key>
          <Key>6</Key>
          <Key>1</Key>
          <Key>2</Key>
          <Key>3</Key>
          <Key>0</Key>
          <Key>.</Key>
          <Key>AC</Key>
          <Equal>=</Equal>
        </Keys>
      </Calculator>
    </CalculatorWrapper>
  )
}

const DefaultKeys = css`
  border: none;
  font-size: ${typeScale.header5};
  inline-size: 60px;
  block-size: 60px;
  border-radius: 40px;
  margin: 0.25rem;
  color: ${neutral.white};
  &:active {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3) inset;
  }
`

const CalculatorWrapper = styled.div`
  background: ${neutral[200]};
  padding-block-start: ${perfectFourth[500]};
  padding-block-end: ${perfectFourth[500]};
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: ${borderRadius.reg};
  color: ${neutral.white};
`

const Calculator = styled.div`
  max-inline-size: 22rem;
  border-radius: ${borderRadius.reg};
  box-shadow: ${boxShadow.reg};
  overflow: hidden;
`

const Output = styled.div`
  background: ${neutral[700]};
  text-align: end;
  padding-block-start: 2rem;
  padding-inline-end: 1.25rem;
  font-size: ${typeScale.header1};
`

const Keys = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1px;
  background: ${neutral[700]};
  padding: 0.5rem;
`

const Key = styled.button`
  ${DefaultKeys};
  background: ${neutral[700]};
`

const Operator = styled.button`
  ${DefaultKeys};
  background: ${neutral[600]};
`

const Equal = styled.button`
  grid-column: 4 / 5;
  grid-row: 2 / span 4;
  ${DefaultKeys};
  background: ${red[500]};
  block-size: auto;
`

export default CalcMinimalDark
