import React from "react";

const Logo = () => {
  return (
    <>
      <svg
        width="49px"
        height="27px"
        viewBox="0 0 49 27"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          id="Rectangle"
          fill="#05CCFF"
          x="0"
          y="0"
          width="8"
          height="5.5"
          rx="2.75"
        ></rect>
        <rect
          id="Rectangle-Copy-3"
          fill="#FF7077"
          x="0"
          y="10.5"
          width="28"
          height="5.5"
          rx="2.75"
        ></rect>
        <rect
          id="Rectangle-Copy-4"
          fill="#AC8CFF"
          x="0"
          y="21"
          width="13.5"
          height="5.5"
          rx="2.75"
        ></rect>
        <rect
          id="Rectangle-Copy-6"
          fill="#05CCFF"
          x="18.5"
          y="21"
          width="30.5"
          height="5.5"
          rx="2.75"
        ></rect>
        <rect
          id="Rectangle-Copy"
          fill="#FFD85B"
          x="13"
          y="0"
          width="36"
          height="5.5"
          rx="2.75"
        ></rect>
        <rect
          id="Rectangle-Copy-2"
          fill="#21DCBE"
          x="33"
          y="10.5"
          width="16"
          height="5.5"
          rx="2.75"
        ></rect>
      </svg>
    </>
  );
};

export default Logo;
