import AnimationExample from "../../../../src/posts/09-2020-creating-playful-text-styles/AnimationExample";
import AnimationBadExample from "../../../../src/posts/09-2020-creating-playful-text-styles/AnimationBadExample";
import HeadlineFullStop from "../../../../src/posts/09-2020-creating-playful-text-styles/HeadlineFullStop";
import HeadlineSmooth from "../../../../src/posts/09-2020-creating-playful-text-styles/HeadlineSmooth";
import ColorfulLinks from "../../../../src/posts/09-2020-creating-playful-text-styles/ColorfulLinks";
import ButtonAngle from "../../../../src/posts/09-2020-creating-playful-text-styles/ButtonAngle";
import ButtonSlideIn from "../../../../src/posts/09-2020-creating-playful-text-styles/ButtonSlideIn";
import ButtonSlideUp from "../../../../src/posts/09-2020-creating-playful-text-styles/ButtonSlideUp";
import CSSEditor from "../../../../src/components/Blog/CSSEditor";
import * as React from 'react';
export default {
  AnimationExample,
  AnimationBadExample,
  HeadlineFullStop,
  HeadlineSmooth,
  ColorfulLinks,
  ButtonAngle,
  ButtonSlideIn,
  ButtonSlideUp,
  CSSEditor,
  React
};