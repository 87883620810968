import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { Blockquotes, PrismSetup, CodeEditor } from "./src/components/Blog";

const components = {
  blockquote: Blockquotes,
  pre: PrismSetup,
  editor: CodeEditor,
};

export const wrapMDX = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>;
};
