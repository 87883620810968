import React from "react"
import styled from "styled-components"
import {
  borderRadius,
  neutral,
  blue,
  yellow,
  red,
  teal,
  purple,
  navy,
  typeScale,
  perfectFourth,
} from "../../utils"

const ColorfulLinks = () => {
  return (
    <TextWrapper>
      <Paragraph>
        Whatever roof party art party,{" "}
        <button class="teal">master cleanse YOLO</button> brunch marfa activated
        charcoal <button class="purple-teal">chartreuse artisan</button> migas
        vexillologist etsy waistcoa leggings. Blue bottle yuccie banjo
        meditation quinoa tbh. <button class="blue">DIY woke pork</button> belly
        semiotics hashtag master{" "}
        <button class="red">cleanse green juice</button> messenger bag
        slow-carb.
      </Paragraph>
    </TextWrapper>
  )
}

const TextWrapper = styled.div`
  background: ${neutral[200]};
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: ${borderRadius.reg};
  padding: ${perfectFourth[500]} ${perfectFourth[600]};
`

const Paragraph = styled.p`
  color: ${navy[700]};
  text-align: center;
  font-size: ${typeScale.paragraph};
  line-height: 1.5;
  button {
    border: none;
    background-color: ${purple[700]};
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: link-animation-rev 1.5s ease forwards;
    &.teal {
      background-image: linear-gradient(60deg, ${teal[500]}, ${blue[600]});
      text-decoration-color: ${teal[500]};
    }
    &.purple-teal {
      background-image: linear-gradient(60deg, ${purple[700]}, ${teal[500]});
      text-decoration-color: ${purple[700]};
    }
    &.blue {
      background-image: linear-gradient(90deg, ${blue[600]}, ${yellow[500]});
      text-decoration-color: ${blue[600]};
    }
    &.red {
      background-image: linear-gradient(
        60deg,
        ${red[500]},
        ${yellow[500]},
        ${red[500]}
      );
      text-decoration-color: ${red[500]};
    }
    &:hover {
      cursor: pointer;
      animation: link-animation-hover 0.5s ease-in forwards;
    }
  }

  @keyframes link-animation-rev {
    0% {
      background-size: 600%;
    }
    20% {
      background-size: 600%;
    }
    40% {
      background-size: 50%;
    }
    100% {
      background-size: 100%;
    }
  }
  @keyframes link-animation-hover {
    0% {
      background-size: 100%;
    }
    80% {
      background-size: 600%;
    }
    100% {
      background-size: 600%;
    }
  }
`

export default ColorfulLinks
