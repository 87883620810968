import React from "react"
import styled from "styled-components"
import { neutral, borderRadius } from "../../utils"

const NoStyles = () => {
  return (
    <CalculatorWrapper>
      <Calculator>
        <Output>0</Output>
        <Keys>
          <Operator>+</Operator>
          <Operator>-</Operator>
          <Operator>&times;</Operator>
          <Operator>÷</Operator>
          <Key>7</Key>
          <Key>8</Key>
          <Key>9</Key>
          <Key>4</Key>
          <Key>5</Key>
          <Key>6</Key>
          <Key>1</Key>
          <Key>2</Key>
          <Key>3</Key>
          <Key>0</Key>
          <Key>.</Key>
          <Key>AC</Key>
          <Equal>=</Equal>
        </Keys>
      </Calculator>
    </CalculatorWrapper>
  )
}

const CalculatorWrapper = styled.div`
  background: ${neutral[200]};
  height: 10rem;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: ${borderRadius.reg};
`

const Calculator = styled.div``

const Output = styled.div``

const Keys = styled.div``

const Operator = styled.button``

const Key = styled.button``

const Equal = styled.button``

export default NoStyles
