import React from "react"
import styled from "styled-components"
import { FiInfo, FiAlertCircle, FiHelpCircle } from "react-icons/fi"
import { GoQuote } from "react-icons/go"
import {
  neutral,
  borderRadius,
  teal,
  yellow,
  navy,
  typeScale,
  transition,
  purple,
} from "../../utils"
import { Code } from "../../templates/post-template.js"

const Blockquotes = ({ children, display }) => {
  if (display === "warning")
    return (
      <Wrapper>
        <div className="container warning">
          <FiAlertCircle className="icon" />
          {children}
        </div>
      </Wrapper>
    )
  if (display === "info")
    return (
      <Wrapper>
        <div className="container info">
          <FiInfo className="icon" />
          {children}
        </div>
      </Wrapper>
    )
  if (display === "question")
    return (
      <Wrapper>
        <div className="container question">
          <FiHelpCircle className="icon" />
          {children}
        </div>
      </Wrapper>
    )
  if (display === "minimal")
    return (
      <Wrapper>
        <div className="container minimal">{children}</div>
      </Wrapper>
    )
  if (display === "default") {
    return (
      <Wrapper>
        <div className="container default">{children}</div>
      </Wrapper>
    )
  } else {
    return (
      <Wrapper>
        <div className="quote">
          <GoQuote className="quote-icon" />
          {children}
        </div>
      </Wrapper>
    )
  }
}

const Wrapper = styled.blockquote`
  max-inline-size: 700px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  .container {
    padding-block-start: 2rem;
    padding-block-end: 2rem;
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
    background: ${neutral[200]};
    border-radius: ${borderRadius.small};
    color: ${neutral[700]};
    border-inline-start: 3px solid ${neutral[500]};
    position: relative;
    margin: 2rem 0;
    p {
      font-size: ${typeScale.paragraph};
      margin-block-start: 0.75rem;
      margin-block-end: 0.75rem;
    }
  }
  @media (min-width: 1170px) {
    .container {
      margin-inline-start: -2rem;
      margin-inline-end: -2rem;
    }
  }
  .icon {
    position: absolute;
    top: 0;
    left: -3px;
    background: ${neutral[100]};
    transform: translate(-50%, -50%);
    inline-size: 40px;
    block-size: 40px;
    border-radius: 50%;
    border: 6px solid ${neutral[100]};
  }
  .info {
    background: ${teal[200]};
    color: ${neutral[700]};
    border-color: ${teal[600]};
    .icon {
      color: ${teal[600]};
    }
    p {
      a {
        color: ${neutral[700]};
        text-decoration-color: ${yellow[500]};
        transition: ${transition.reg};
        background: transparent;
        background: linear-gradient(
          to top,
          ${yellow[400]} 50%,
          ${teal[200]} 50%
        );
        background-size: 100% 200%;
        background-position: right top;
        &:hover {
          background-position: right bottom;
          color: ${neutral[700]};
        }

        background-size: 100% 200%;
      }
    }
    code {
      background: ${teal[300]};
      font-weight: bold;
      ${Code}
      font-size: ${typeScale.smallText};
    }
  }
  .warning {
    background: ${yellow[200]};
    color: ${yellow[900]};
    border-color: ${yellow[700]};
    .icon {
      color: ${yellow[700]};
    }
  }
  .minimal {
    background: ${neutral[100]};
    border-inline-start: none;
    text-align: center;
    font-size: ${typeScale.header5};
    color: ${neutral[600]};
    font-style: italic;
    letter-spacing: 1px;
    padding-block-start: 2rem;
    padding-block-end: 2rem;
    padding-inline-start: 2.5rem;
    padding-inline-end: 2.5rem;
  }
  .question {
    background: ${purple[200]};
    color: ${navy[500]};
    border-color: ${purple[600]};
    .icon {
      color: ${purple[600]};
    }
    p {
      a {
        text-decoration-color: ${yellow[500]};
        color: ${navy[500]};
        transition: ${transition.reg};
        background: transparent;
        background: linear-gradient(
          to top,
          ${yellow[400]} 50%,
          ${purple[200]} 50%
        );
        background-size: 100% 200%;
        background-position: right top;
        &:hover {
          background-position: right bottom;
          color: ${navy[500]};
        }

        background-size: 100% 200%;
      }
    }
  }
  .quote {
    @media (min-width: 576px) {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 2rem;
      align-items: center;
    }
    font-style: italic;
    color: ${navy[500]};
    line-height: 1.8;
    word-spacing: 2px;
    font-size: 1.2rem;
    margin-block-start: 2rem;
    margin-block-end: 2rem;
    margin-inline-start: 0;
    margin-inline-end: 0;
    .quote-icon {
      font-size: 6rem;
      color: ${navy[500]};
    }
  }
`

export default Blockquotes
