import NoStyles from "../../../../src/posts/09-2020-calculator/NoStyles";
import CalcWrapper from "../../../../src/posts/09-2020-calculator/CalcWrapper";
import CalcDisplay from "../../../../src/posts/09-2020-calculator/CalcDisplay";
import CalcLayout from "../../../../src/posts/09-2020-calculator/CalcLayout";
import CalcCleanup from "../../../../src/posts/09-2020-calculator/CalcCleanup";
import CalcBorder from "../../../../src/posts/09-2020-calculator/CalcBorder";
import CalcPolish from "../../../../src/posts/09-2020-calculator/CalcPolish";
import CalcPlayful from "../../../../src/posts/09-2020-calculator/CalcPlayful";
import CalcPlayfulDark from "../../../../src/posts/09-2020-calculator/CalcPlayfulDark";
import PlayfulCalcStart from "../../../../src/posts/09-2020-calculator/PlayfulCalcStart";
import PlayfulCalcCircles from "../../../../src/posts/09-2020-calculator/PlayfulCalcCircles";
import CalcMinimal from "../../../../src/posts/09-2020-calculator/CalcMinimal";
import CalcMinimalDark from "../../../../src/posts/09-2020-calculator/CalcMinimalDark";
import * as React from 'react';
export default {
  NoStyles,
  CalcWrapper,
  CalcDisplay,
  CalcLayout,
  CalcCleanup,
  CalcBorder,
  CalcPolish,
  CalcPlayful,
  CalcPlayfulDark,
  PlayfulCalcStart,
  PlayfulCalcCircles,
  CalcMinimal,
  CalcMinimalDark,
  React
};