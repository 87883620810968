import React from "react"
import styled from "styled-components"
import {
  typeScale,
  purple,
  transition,
  yellow,
  navy,
  teal,
  red,
  breakpoint,
  borderRadius,
} from "../utils"
import { Link } from "gatsby"
import { FiArrowRight } from "react-icons/fi"

const Button = ({ link, title, primary, color, arrow }) => {
  if (primary) {
    return (
      <PrimaryButton to={link} className={color}>
        {title}
        {arrow === "true" && <FiArrowRight />}
      </PrimaryButton>
    )
  }
  return (
    <SecondaryButton to={link} className={color}>
      {title}
      <FiArrowRight />
    </SecondaryButton>
  )
}

const ButtonBase = styled(Link)`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: ${transition.reg};
  border-radius: ${borderRadius.pill};
  font-size: ${typeScale.paragraph};
  font-weight: bold;
  letter-spacing: 0.8px;
  text-decoration: none;
  svg {
    transform: translateX(5px);
    transition: ${transition.reg};
  }
  &:hover {
    svg {
      transform: translateX(7px);
    }
  }
`

const PrimaryButton = styled(ButtonBase)`
  background: ${yellow[500]};
  color: ${navy[600]};
  padding-block-start: 12px;
  padding-block-end: 12px;
  padding-inline-start: 45px;
  padding-inline-end: 40px;
  outline: none;
  @media ${breakpoint.md} {
    padding-inline-start: 30px;
    padding-inline-end: 30px;
  }
  &:hover,
  &:focus,
  &:active {
    background: ${yellow[400]};
    color: ${navy[600]};
  }
  &.red {
    background: ${red[500]};
    color: ${navy[800]};
    font-weight: bold;
    &:hover,
    &:focus,
    &:active {
      background: ${red[400]};
    }
  }
  &.red-light {
    background: ${red[300]};
    color: ${navy[800]};
    font-weight: bold;
    &:hover,
    &:focus,
    &:active {
      background: ${red[400]};
    }
  }
`

export const SecondaryButton = styled(ButtonBase)`
  background: ${purple[600]};
  color: white;
  padding-block-start: 8px;
  padding-block-end: 8px;
  padding-inline-start: 35px;
  padding-inline-end: 30px;
  font-weight: 400;
  &:hover {
    background: ${purple[700]};
  }
  &.teal {
    background: ${teal[600]};
    &:hover {
      background: ${teal[800]};
    }
  }
  &.navy {
    background: ${navy[400]};
    &:hover {
      background: ${navy[600]};
    }
  }
  &.yellow {
    background: ${yellow[500]};
    color: ${navy[600]};
    font-weight: bold;
    &:hover,
    &:focus,
    &:active {
      background: ${yellow[400]};
      color: ${navy[600]};
    }
  }
`

export default Button
