import styled, { css } from "styled-components"
import { typeScale, navy, red, breakpoint, teal, yellow } from "../utils"

export const HeaderWithDepth = css`
  line-height: 1.1;
  color: ${navy[500]};
  text-shadow: -3px 3px 0px ${navy[300]};
  letter-spacing: 1px;
  span {
    color: ${red[500]};
    text-shadow: -3px 3px 0px ${red[300]};
  }
  @media ${breakpoint.md} {
    text-align: center;
  }
`
export const HeaderOne = styled.h1`
  ${HeaderWithDepth}
  font-size: ${typeScale.header1};
  @media ${breakpoint.md} {
    font-size: ${typeScale.header2};
  }
`
export const BlogTitleHeader = styled.h1`
  ${HeaderWithDepth}
  font-size: ${typeScale.header2};
`
export const HeaderTwo = styled.h2`
  font-size: ${typeScale.header3};
  letter-spacing: 1px;
  line-height: 1.3;
  color: ${navy[400]};
  span {
    color: ${teal[500]};
  }
  @media ${breakpoint.md} {
    text-align: center;
  }
`

export const HeaderThree = styled.h3`
  font-size: ${typeScale.header4};
  letter-spacing: 1px;
  line-height: 1.3;
  color: ${navy[500]};
  span {
    font-size: ${typeScale.smallText};
    background: ${yellow[400]};
    padding-block-start: 0.2rem;
    padding-block-end: 0.2rem;
    padding-inline-start: 0.5rem;
    padding-inline-end: 0.5rem;
    border-radius: 50px;
    color: ${yellow[800]};
    font-weight: 400;
    vertical-align: 40%;
  }
  @media ${breakpoint.md} {
    text-align: center;
  }
`
