import React from "react"
import styled from "styled-components"
import {
  neutral,
  borderRadius,
  boxShadow,
  perfectFourth,
  typeScale,
} from "../../utils"

const CalcDisplay = () => {
  return (
    <CalculatorWrapper>
      <Calculator>
        <Output>0</Output>
        <Keys>
          <Operator>+</Operator>
          <Operator>-</Operator>
          <Operator>&times;</Operator>
          <Operator>÷</Operator>
          <Key>7</Key>
          <Key>8</Key>
          <Key>9</Key>
          <Key>4</Key>
          <Key>5</Key>
          <Key>6</Key>
          <Key>1</Key>
          <Key>2</Key>
          <Key>3</Key>
          <Key>0</Key>
          <Key>.</Key>
          <Key>AC</Key>
          <Equal>=</Equal>
        </Keys>
      </Calculator>
    </CalculatorWrapper>
  )
}

const CalculatorWrapper = styled.div`
  background: ${neutral[200]};
  padding-block-start: ${perfectFourth[500]};
  padding-block-end: ${perfectFourth[500]};
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: ${borderRadius.reg};
`

const Calculator = styled.div`
  max-inline-size: 22rem;
  border-radius: ${borderRadius.reg};
  box-shadow: ${boxShadow.reg};
  overflow: hidden;
`

const Output = styled.div`
  background: ${neutral[800]};
  color: ${neutral.white};
  text-align: end;
  padding-block-start: 0.5rem;
  padding-block-end: 0.5rem;
  padding-inline-end: 1rem;
  font-size: ${typeScale.header3};
`

const Keys = styled.div``

const Operator = styled.button``

const Key = styled.button``

const Equal = styled.button``

export default CalcDisplay
